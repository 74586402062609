import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbDate, NgbDatepicker, NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FacilityChooserService} from '../../../../@core/facility-chooser.service';
import {fromNgbDateStructTo, getNgbDate, getNgbMonth, getNgbTime, NgbDateTimeStruct, NgbTimeStruct, toNgbDateStruct, toNgbDateStructFrom} from '../../../../utils/date.utils';
import {DateTime} from 'luxon';

@Component({
    selector: 'datepicker',
    template: `
        <div class="d-flex flex-column align-items-center">
            <ngb-datepicker #dp
                            [(ngModel)]="ngbDate"
                            (dateSelect)="onSelectDates($event)"
                            [dayTemplate]="dayTemplate"
                            (ngModelChange)="setDateTime()"
                            [disabled]="disabled"
                            [minDate]="{year: 1900, month: 1, day: 1}">
            </ngb-datepicker>
            <ngb-timepicker *ngIf="hasTime" [(ngModel)]="ngbTime" (ngModelChange)="setDateTime()" [seconds]="hasSeconds" [disabled]="disabled"></ngb-timepicker>
        </div>
    `,
    providers: [NgbDatepickerConfig],
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit {
    // TODO: implement moment and text formats
    @Input() date: Date | NgbDateStruct | string;
    @Output() dateChange = new EventEmitter<Date | NgbDateStruct | string>();
    @Output() dateSelectChange = new EventEmitter<NgbDate>();

    @Input() format: 'date' | 'dateTime' | 'ngbDate' | 'text' = 'date';
    @Input() hasTime = false;
    @Input() hasSeconds = false;
    @Input() needsTimezone = false;
    @Input() initNow = false;
    @Input() disabled = false;
    @Input() dayTemplate: TemplateRef<any>;

    @ViewChild('dp', {static: true}) ngbDp: NgbDatepicker;
    ngbDate: NgbDateStruct;
    ngbTime: NgbTimeStruct = {};
    ngbDateTime: NgbDateTimeStruct;

    constructor(
        private fc: FacilityChooserService) {
    }

    // TODO: implement OnChanges
    ngOnInit() {
    }

    ngOnChanges() {
        if (!this.date && this.initNow) {
            this.ngbDateTime = this.toNgbDateStructFrom.dateTime(DateTime.now());
        } else if (this.date) {
            this.ngbDateTime = toNgbDateStruct(this.date, this.hasTime, this.needsTimezone ? this.fc.timezone : null);
        } else {
            this.ngbDateTime = null;
        }
        this.ngbDate = this.ngbDateTime ? getNgbDate(this.ngbDateTime) : null;
        if (this.hasTime) this.ngbTime = this.ngbDateTime ? getNgbTime(this.ngbDateTime, this.hasSeconds) : {hour: 12, minute: 0};
        if (this.ngbDateTime) this.ngbDp.navigateTo(getNgbMonth(this.ngbDateTime));
    }

    ngAfterViewInit() {
        if (this.ngbDateTime) this.ngbDp.navigateTo(getNgbMonth(this.ngbDateTime));
    }

    setDateTime(): void {
        if (!this.ngbDate) return;
        this.ngbDateTime = {...this.ngbDate, ...this.ngbTime};
        this.date = this.fromNgbDateStructTo[this.format](this.ngbDateTime);
        this.dateChange.emit(this.date);
    }

    get toNgbDateStructFrom() {
        return toNgbDateStructFrom(this.hasTime, this.needsTimezone ? this.fc.timezone : null);
    }

    get fromNgbDateStructTo() {
        return fromNgbDateStructTo(this.hasTime, this.needsTimezone ? this.fc.timezone : null);
    }

    onSelectDates(date: NgbDate) {
        this.dateSelectChange.emit(date);
    }
}
