import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatepickerComponent} from './datepicker.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
    selector: 'datepicker-select',
    template: `
        <div class="body-dropdown-wrapper dropdown-align-{{alignment}} d-flex" (clickOutside)="close($event)">
            <input #datePickerInput
                   type="text"
                   [ngModel]="dateCaption"
                   (click)="$event.stopPropagation(); open()"
                   (keyup)="parseInputOnEnter($event)"
                   (blur)="onBlur($event)"
                   [disabled]="disabled"
                   class="datepicker-select-input flex-grow-1 {{controlClass || 'body-select'}}"
                   [class.icon]="icon"
                   [placeholder]="placeholder || ''"
                   [class.text-placeholder]="!dateCaption"/>
            <fal [i]="icon" *ngIf="icon"
                 class="icon datepicker-select-icon text-{{disabled ? 'muted' : 'primary'}}"></fal>
            <span *ngIf="clearable && date"
                  title="Clear"
                  class="datepicker-select-clear-wrapper icon"
                  (click)="clear($event)">
                <span class="datepicker-select-clear">×</span>
            </span>
            <div class="body-dropdown-content" [hidden]="!datepickerOpen">
                <datepicker #datepicker
                            [(date)]="date"
                            (click)="$event.stopPropagation(); onDatePickerClick()"
                            (dateChange)="onDateChange()"
                            [format]="format"
                            [hasTime]="hasTime"
                            [hasSeconds]="hasSeconds"
                            [needsTimezone]="needsTimezone"
                            [initNow]="initNow"
                            [disabled]="disabled">
                </datepicker>
            </div>
        </div>
    `,
    styleUrls: ['./datepicker-select.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerSelectComponent), multi: true},
    ],
})
export class DatepickerSelectComponent implements OnChanges, ControlValueAccessor {
    // TODO: implement moment and text formats
    @Input() date: Date | NgbDateStruct | string;
    @Output() dateChange = new EventEmitter<Date | NgbDateStruct | string>();

    @Input() format: 'date' | 'dateTime' | 'ngbDate' | 'text' = 'date';
    @Input() hasTime = false;
    @Input() hasSeconds = false;
    @Input() needsTimezone = false;
    @Input() initNow = false;
    @Input() controlClass: string;
    @Input() alignment: 'left' | 'right' = 'right';
    @Input() icon = 'calendar';
    @Input() placeholder: string = '';
    @Input() disabled = false;
    @Input() clearable = true;

    @HostListener('click', ['$event']) click() {
        if (this.disabled) return;

        this.open();
    }

    @ViewChild('datePickerInput', {static: true}) datePickerInput: ElementRef;
    @ViewChild(DatepickerComponent, {static: true}) dp: DatepickerComponent;
    datepickerOpen = false;
    dateCaption: string;
    private parseDateInput$ = new Subject<boolean>();

    ngOnChanges() {
        this.updateDateCaption();
    }

    parseInputOnEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this._parseDateInput(event.target['value']);
        }
    }

    onBlur(event: FocusEvent) {
        this.parseDateInput$
            .pipe(take(1))
            .subscribe(canParse => canParse && this._parseDateInput(event.target['value']));
    }

    onDatePickerClick() {
        this.parseDateInput$.next(false);
    }

    onDateChange(close = true) {
        this.updateDateCaption();
        if (!this.date) return;
        this.dateChange.emit(this.date);
        this.propagateChange(this.date);
        if (!this.hasTime && close) this.close();
    }

    updateDateCaption() {
        setTimeout(() => {
            this.dateCaption = this._getDateCaption();
        });
    }

    open() {
        setTimeout(() => this.datepickerOpen = true);
    }

    close(event?) {
        if (event) this.parseDateInput$.next(true);
        if (this.datepickerOpen) this.datepickerOpen = false;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    propagateChange = (_: any) => {
    };

    writeValue(obj: any): void {
        this.date = obj;
        this.updateDateCaption();
    }

    clear(event?, focus = true) {
        event?.stopPropagation();
        this.date = null;
        this.dateChange.emit(this.date);
        this.propagateChange(this.date);
        this.updateDateCaption();
        if (focus) this.datePickerInput.nativeElement.focus();
    }

    private _parseDateInput(value: string) {
        if (value) {
            const newDateValue = new Date(value);
            if (isNaN(newDateValue.getTime())) return;
            this.dp.ngbDate = {
                year: newDateValue.getFullYear(),
                month: newDateValue.getMonth() + 1,
                day: newDateValue.getDate(),
            };
            this.dp.setDateTime();
        } else {
            this.clear(undefined, false);
        }
    }

    // TODO: implement string method from date utils
    private _getDateCaption(): string {
        return this.dp.ngbDateTime ? this.dp.fromNgbDateStructTo.dateTime(this.dp.ngbDateTime).toFormat(`MM/dd/yyyy${this.hasTime ? `  HH:mm${this.hasSeconds ? ':ss' : ''}` : ''}`) : '';
    }
}
